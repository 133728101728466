export default {
  Resurrection: 'Взять карту воина из вашего стока и разыграть ее бесплатно. Возрождение не может быть отложенной картой',
  Retribution: 'Союзный воин получает +1 к атаке на один удар, и + 1 к атаке за каждое ваше положительное заклиание на союзных воинах или на поле боя',
  AttackAura: 'Кладется на союзного воина. Атакует ближайшего вражеского воина, стоящего в одной шеренге с этим воином, силой 2 при наложении и как ПОСЛЕДНИЙ ВЫЗОВ',
  HealingLight: 'Вылечить 3 здоровья у союзного воина, или 4 здоровья, если это отложенная карта',
  Enlightenment: 'Кладется рядом с полем боя, союзные воины получают + 1 к атаке в свой ход',
  HeavenProtection: 'Кладется на союзного воина. Поглощает урон от атаки вражеского воина во время хода врага, после этого Божественная защита отправляется в сток',
  Bless: 'Кладется на союзного воина - он получает + 1 к базовой атаке',
  HolyLand: 'Кладется на центральную клетку. Атакует вражеских воинов силой 2, которые перемещается или входит в игру в шеренгу, где есть святая земля',
  HeavenShock: 'Отнять 2 здоровья у вражеского воина на поле и вылечить 2 здоровья у союзного героя',
  SaintWord: 'Атаковать силой 2 или силой 3, если отложенная карта, всех вражеских воинов в шеренге, а также силой 2 вражеских воинов в соседней шеренге (приоритет - верхняя шеренга)',
  LightShield: 'Кладется на союзного воина. Он получает +2 к здоровью',
  LastChance: 'РЕАКЦИЯ. Во время хода врага, при нанесении смертельного урона вражеским воином вашему воину, ваш воин не умирает, а остается на поле с 1 здоровьем',
  Conciliation: 'Кладется рядом с полем боя. Предотвращает любой урон по союзным воинам и герою во время хода противника',
};
