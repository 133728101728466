import tutorialText from './tutorialTextEN';
import { menuTipsHeaders, menuTipsText } from './menuTipsEn';
import AcademiaInfo from './academiaCardsInfoEN';
import CastleInfo from './castleCardsInfoEN';
import castleCardsNames from './castleCardsNamesEN';
import academiaCardsNames from './academiaCardsNamesEN';

export default {
  translation: {
    mainDescription: ` - is a collectible card game created on basis of Might and Magic: Duel of Champions (MMDoС) that is no longer officially supported.
    MMDoC had many fans, but it also had pretty complicated gameplay for average players and could exist as computer game only. In Duel of Warlords original mechanic of MMDoC was reworked:
    it has become simpler to understand but new features were also added in order to make it exciting to play and hard to master, overall this allows a new game to be made as a board game alongside with a digital version!`,
    gameDescription: `The game is a 2-player tactical duel in a fantasy world inspired by the Heroes of Might and Magic series of games.
    Each of the players controls one of the unique warlords in the game belonging to the chosen faction.
    During the game, opponents take turns playing spells and warriors cards, as well as moving them around the battlefield to achieve the main goal - to reduce the enemy warlord's health to 0 points`,
    gameFactions: `Choose one of the 4 available factions in the game:
    The Castle - strong warriors of the light, the Academy - powerful wizards, the Dungeon - dangerous killers, the Forest - adherents of the magic of nature.`,
    hoverPictures: '(move the cursor over the images)',
    moreAboutMMdoc: 'More about MMDoC',
    Battlefield: 'BATTLEFIELD',
    Heroes: 'WARLORDS',
    Spells: 'SPELLS',
    PostponedCard: 'POSTPONED CARD',
    BattlefieldDescription: `There are 3 classes of warriors in the game: melee fighters, flying and shooters.
    You need to place them correctly on the battlefield to take advantage of each class.
    Move the warriors around the battlefield to prevent the enemy from attacking your warlord!`,
    HeroPowerDescription: `Warlords are directly involved in the battle: they attack the enemy or use useful spells!
    Each warlord has a standard ability and a unique one that should be taken into account when you are assembling your deck.`,
    SpellsDescription: '6 spells schools and three types of magic cards: make strikes against enemy warriors with an instant type of magic, take control over battlefield with temporary effects or increase your warriors power with permanent spells!',
    PostponedDescription: 'You can make any card postponed during your turn that allows you to perform one of the following moves: increase spell power of attacking spells, make an unexpected strike agains your enemy or simply play combo of your own!',
    GameDescriptTitle: 'GAME DESCRIPTION',
    GameFactionsTitle: 'GAME FACTIONS',
    GameFeaturesTitle: 'GAME FEATURES',
    FeedBackLine: 'For feedback please contact us on discord: ',
    VoiceLobby: 'Voice lobby: ',
    Contact: 'Contact us:',
    PageNotFound: 'Page not found',
    YouCanChangeover: 'You can proceed',
    GoToMain: 'on the main page',
    midSpell: 'MIDDLE CELL',
    topSpell: 'CLOSE TO LINE',
    bigSpell: 'COMMON CELL',
    field: 'WARRIOR FIELD',
    Hero: 'WARLORD',
    PostponedSpell: 'POSTPONED CARD',
    Graveyard: 'GRAVE',
    Deck: 'DECK',
    RotateScreen: 'ROTATE YOUR SCREEN',
    ActiveCard: 'Active card',
    Player1: 'Player 1',
    Player2: 'Player 2',
    healthBar: 'CHANGE',
    turnLeft: 'ROTATE',
    turnRight: 'ROTATE',
    return: 'TO HAND',
    deckreturn: 'TO DECK',
    ability: 'ABILITY',
    graveyard: 'TO GRAVE',
    switchcard: 'REPLACE',
    addToDeck: 'ADD',
    deleteFromDeck: 'DELETE',
    CLOSE: 'CLOSE',
    Change: 'CHANGE',
    ChangeHP: 'CHANGE HEALTH POINTS',
    PlayedCards: 'PLAYED CARDS',
    ChangePoints: 'CHANGE ACTION POINTS',
    ChooseGame: 'CHOOSE GAME',
    Tutorial: 'TUTORIAL',
    HotSeat: 'TRAINING',
    OnlineGame: 'ONLINE',
    DeckBuilder: 'DECK BUILDER',
    CREATEDECK: 'CREATE NEW',
    SAVEDECK: 'SAVE DECK',
    DeckName: 'Deck\'s name',
    YourDecks: 'Your decks',
    AvailableCards: 'Available cards',
    DeckSaved: 'Your deck succesfully saved!',
    DeleteDeckWarn: 'Are you sure you want to delete the deck?',
    SaveChangesWarn: 'Are you sure you want exit without saving changes?',
    ChooseFactions: 'CHOOSE FACTION AND WARLORD',
    START: 'BEGIN',
    BACK: 'BACK',
    DrawCards: 'DRAW CARD TO FULL HAND?',
    DrawCard: 'DRAW',
    ResetBtn: 'RESET',
    CONTINUE: 'CONTINUE',
    DELETE: 'DELETE',
    JOIN: 'ПРИСОЕДИНИТЬСЯ',
    PLAY: 'PLAY',
    FEATURES: 'FEATURES',
    Install: 'INSTALL',
    EndTurn: 'END TURN',
    Exit: 'EXIT',
    TutorialWelcome: 'Welcome to Tutorial mode!',
    TutorialDescription: 'Here you will learn the basics of the Duel of Warlords game',
    TutorialFinish: 'Gongratulations! You have completed Duels of Warlords tutorial',
    TutorialTips: 'Below is additional tips that you need to know in a normal game mode',
    MAIN: 'MAIN PAGE',
    GAMECHOOSE: 'CHOOSE GAME',
    RESETGAME: 'RESET GAME',
    CREATEROOM: 'CREATE GAME',
    GOLOBBY: 'GO TO LOBBY',
    Tips: 'TIPS',
    Tip1: 'You can always click on the graveyard to see the cards played by you or your opponent',
    Tip2: 'In the first round of the game, you can one time throw any number of cards from your hand to the end of the deck and take the same number in return from the top of your deck',
    Tip3: 'During the game, once per round, a player can put 1 card from his hand into the deck and draw a new card in return',
    Tip4: 'If you click on the text field on an active card you\'ll be able to see detailed card\'s description',
    Tip5: 'If you are player#1 so the current number of cards in the opponent\'s hand is displayed in the right upper corner of the playing field, if you are player#2 then in the left upper corner',
    ChatHeader: 'Welcome to the chat!',
    CurrentRooms: 'CURRENT GAMES',
    YourName: 'Your name',
    Password: 'Password...',
    RoomsOwner: 'Room\'s owner: ',
    EmptySlot: 'VACANT SLOT',
    SEND: 'SEND',
    WaitForPLayer: 'Waiting for another player...',
    PlayerDisconnected: 'Player disconnected from the server: ',
    YouDisconnected: 'You disconnected from the server',
    GameEnd: 'The game is over! Player won: ',
    ResetWarning: 'If you continue current progress will be reset. Continue?',
    EndTurnWarning: 'You have an active warriors on the battlefield, are you sure you want to continue?',
    CurrentOnline: 'Players online',
    Chat: 'Chat:',
    ConnectProblem: 'One of the players experiencing connection problem: try to perform action one more time',
    LogIn: 'Log in',
    SignUp: 'Sign up',
    SIGNUP: 'SIGN UP',
    ENTER: 'LOG IN',
    EXIT: 'EXIT',
    DontHaveAcc: 'Don\'t have an account?',
    HaveAcc: 'Already have account?',
    SignUpLink: 'SIGN UP',
    LogInLink: 'LOG IN',
    LoginName: 'Login',
    RepeatPassword: 'Repeat password...',
    CantBuildDeck: '(You will not be able to build your own deck)',
    GuestSignIn: 'Continue as a guest',
    Loading: 'Loading...',
    errors: {
      RoomNotExist: 'The room doesn\'t exist!',
      RoomEmpty: 'This room is empty!',
      RoomFull: 'This room is full!',
      IncorrectPass: 'Incorrect password!',
      AuthOrNetError: 'Ошибка авторизации или соединения',
      AuthorizationError: 'Пожалуйста, авторизуйте аккаунт',
      LoginError: 'Пожалуйста, проверьте данные для входа',
      Conflict: 'Такой пользователь уже существует',
      Required: 'Required field',
      OnlyNumber: 'Допустимы только цифры',
      Min3Max10: 'The length from 3 up to 10 symbols',
      Min3Max20: 'The length from 3 up to 20 symbols',
      Min6: 'Не менее 6 символов',
      Unique: 'Этот чат уже добавлен',
      UniqueName: 'Такое имя уже есть',
      Identical: 'Пароли должны совпадать',
      meetDeckRequirments: 'Choose hero!',
      MaximumDecks: 'You already created maximum decks!',
      YouNeedOneDeck: 'You need at least one deck!',
      NetworkError: 'Network error',
      false: '',
    },
    description: {
      Castle: CastleInfo,
      Academia: AcademiaInfo,
    },
    titles: {
      Castle: castleCardsNames,
      Academia: academiaCardsNames,
    },
    tutorialSteps: tutorialText,
    menuTipsHeader: menuTipsHeaders,
    menuTipsDescription: menuTipsText,
  },
};
