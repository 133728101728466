export default {
  LivingWall: 'БОЕЦ. НЕ ДВИГАЕТСЯ',
  MageApprentice: 'СТРЕЛОК. Может атаковать любого воина на поле',
  GremlinShooter: 'СТРЕЛОК. Имеет ответный удар стрелкам силой 1',
  IceElement: 'СТРЕЛОК. Перед смертью, оглушает ближайшего вражеского воина в шеренге где он стоял',
  EarthGolem: 'БОЕЦ. Вместо своего хода, вылечить 1 здоровье у союзных воинов в прилегающих клетках',
  Gargoyle: 'ЛЕТАЮЩИЙ. Когда Стрелки в свой ход атакуют Гаргулью силой атаки более 1, урон от этих атак снижается на 1 единицу',
  FireGolem: 'БОЕЦ. ПОСЛЕДНИЙ ВЫЗОВ: отнимает 1 здоровье у ВСЕХ воинов в шеренге в которой он находился',
  Rakshasa: 'БОЕЦ. Имеет УКЛОНЕНИЕ',
  YoungGenie: 'ЛЕТАЮЩИЙ. Когда входит в игру, может переместить любого воина на поле на соседнюю пустую клетку',
  EnergyElemental: 'СТРЕЛОК. Имеет ПРОНИКАЮЩУЮ АТАКУ 2',
  IceGiant: 'БОЕЦ. При входе в игру оглушает союзных и вражеских воинов в шеренге в которую входит',
  ArcaneArmor: 'РЕАКЦИЯ: Во время хода врага, поглотить урон от первой атаки воинов силой 3 и более по вашему воину или герою',
  Blizzard: 'Накладывается на центральную клетку. Отнимает 2 здоровье у вражеских и соююзных воинов в этой шеренге при наложении и как ПОСЛЕДНИЙ ВЫЗОВ',
  CursedChackle: 'Накладывается на вражеского воина. После того как этот воин нанес урон в свой ход, нанести такой же урон вражескому герою',
  Frostbite: 'Накладывается на вражеского воина и отнимает у него -2 базовой атаки',
  FrostTrap: 'РЕАКЦИЯ: Вражеский воин, который только сделал шаг или вошел в игру, а также наложенные заклинания отправляются в руки к владельцам в изначальном состоянии',
  IceBlock: 'Кладется на союзного героя. Поглощает весь урон по герою в течение хода врага',
  IceSpikes: 'Отнять 2 здоровья у ВСЕХ воинов на целевой линии',
  IceWall: 'Кладется на центральную клетку. ВСЕ Стрелки и Бойцы в этой шеренге не могут атаковать в свой ход',
  IceWeapon: 'Накладывается на союзного воина. Он получает +2 к базовой атаке',
  Icing: 'Кладется на центральную клетку. ВСЕ Воины в этой шеренге не могут передвигаться и атаковать в свой ход',
  InvisibilityCloak: 'Накладывается на союзного воина. Он получает НЕВИДИМОСТЬ и МАГИЧЕСКИЙ ЩИТ',
  SoulDrain: 'Уничтожить вражеского воина на поле боя',
  Teleport: 'Переместить союзного воина на любую свободную клетку (подходящую ему)',
  TownPortal: 'Вражеский воин, а также наложенные на него заклинания, отправляются в руки к владельцам в изначальном состоянии',
  Transformation: 'Вражеский воин получает ОГЛУШЕНИЕ и вы можете передвинуть его на соседнюю клетку',
  Tsunami: 'Отнять 7 здоровья у ВСЕХ не Летающих воинов на поле боя или 8 здоровья, если это отложенная способность',
  Waterfall: 'Кладется рядом с полем боя. ВСЕ воины с текущей базовой атакой 2 или меньше не могут атаковать в свой ход',
  IceArrow: 'Отнять 2 здоровья у вражеского воина, этот воин получает ОГЛУШЕНИЕ',
  IceArmor: 'Накладывается на союзного воина или героя. Весь урон по этому воину снижается на 1 (в том числе от ответного удара)',
  YoungGenieCast: 'Выбрать воина для перемещения',
  Academia: 'Могучие маги повелевающие элементалями',
  NalaHero: 'Стрела магии: отнять 1 здоровье у воина на поле боя за 2д. Сила: взять карту из колоды за 1д.',
  factionInfo: 'Могучие маги повелевающие элементалями и искусно владеющие заклинаниями контроля',
};
