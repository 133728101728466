export default {
  ImperialShooter: 'СТРЕЛОК.',
  MasterShooter: 'СТРЕЛОК. Имеет ответный удар силой 1',
  YoungPriestess: 'СТРЕЛОК. СТАРТ ХОДА: Вылечивает 1 здоровье у случайного прилегающего союзного воина',
  WarriorOfLight: 'БОЕЦ. Имеет СТРЕМИТЕЛЬНОСТЬ',
  ValorKnight: 'БОЕЦ. Вылечивает 1 здоровье у союзных воинов в соседних клетках на линии при атаке в свой ход.',
  Bannerman: 'БОЕЦ. Когда входит в игру, прибавить союзному воину на поле + 2 к атаке на один его ответный удар или атаку',
  Defender: 'БОЕЦ. Имеет ЩИТ МАГИИ. Вражеские воины не могут атаковать другого воина в шеренге, пока жив Защитник',
  RighteousFighter: 'БОЕЦ. Получает +1 к атаке по герою',
  KnightGuardian: 'БОЕЦ. Вражеские воины не могут атаковать воинов в соседних клетках от Рыцаря-защитника, но могут атаковать Рыцаря-защитника вместо этого',
  ImperialGriffon: 'ЛЕТАЮЩИЙ. Имеет +1 к ответному удару против Бойцов',
  TemplarChampion: 'БОЕЦ. Когда входит в игру, вылечить 3 здоровья у союзного воина на поле. Имеет ПРОНИКАЮЩУЮ АТАКУ 2',
  MasterOfLight: 'БОЕЦ. В свой ход, вместо базовой атаки, может отнять 2 здоровья у 2-х вражеских воинов в шеренге в которой стоит не получая ответного удара',
  ThunderBlast: 'Отнять у воина 3 здоровья или 4, если это отложенная карта. Оглушить союзных и вражеских прилегающих к цели других воинов',
  Swiftness: 'Накладывается на союзного воина. Он может атаковать и ходить в один ход',
  Resurrection: 'Взять карту воина из вашего стока и разыграть ее бесплатно. Возрождение не может быть отложенной картой',
  Retribution: 'Союзный воин получает +1 к атаке на один удар, и + 1 к атаке за каждое ваше положительное заклиание на союзных воинах или на поле боя',
  AttackAura: 'Кладется на союзного воина. Атакует случайного вражеского воина, стоящего в одной шеренге с этим воином, силой 2 при наложении и как ПОСЛЕДНИЙ ВЫЗОВ',
  HealingLight: 'Вылечить 3 здоровья у союзного воина, или 4 здоровья, если это отложенная карта',
  YouthFountain: 'Положить рядом с полем. Когда другой игрок выкладывает или перемещает своего воина на поле - вылечить 2 здоровья у вашего героя',
  Enlightenment: 'Кладется рядом с полем боя, союзные воины получают + 1 к атаке в свой ход',
  HeavenProtection: 'Кладется на союзного воина. Поглощает урон от атаки вражеского воина во время хода врага, после этого Божественная защита отправляется в сток',
  Bless: 'Кладется на союзного воина - он получает + 1 к базовой атаке',
  HolyLand: 'Кладется на центральную клетку. Атакует вражеских воинов силой 2, которые перемещается или входит в игру в шеренгу, где есть святая земля',
  HeavenShock: 'Отнять 2 здоровья у вражеского воина на поле и вылечить 2 здоровья у союзного героя',
  SaintWord: 'Атаковать силой 2 или силой 3, если отложенная карта, всех вражеских воинов в шеренге, а также силой 2 вражеских воинов в соседней шеренге (приоритет - верхняя шеренга)',
  RighteousHammer: 'Отнять у вражеского воина 3 здоровья + Х здоровья, где Х равен: количество воинов на поле у противника минус количество воинов у вас на поле',
  RetaliationHammer: 'Отнять 2 здоровья у вражеского воина и переместить его на прилегающую клетку',
  LightShield: 'Кладется на союзного воина. Он получает +2 к здоровью',
  LastChance: 'РЕАКЦИЯ. Во время хода врага, при нанесении смертельного урона вражеским воином вашему воину, ваш воин не умирает, а остается на поле с 1 здоровьем',
  Conciliation: 'Кладется рядом с полем боя. Предотвращает любой урон по союзным воинам и герою во время хода противника',
  TemplarChampCast: 'Выбрать воина для лечения',
  BannermanCast: 'Выбрать воина для увеличения атаки',
  Castle: 'Рыцари и ангелы полагающиеся на магию света',
  ZigfridHero: 'Удар молотом: атаковать силой 1 воина на поле боя и передвинуть его на соседнюю клетку в линии - 4д. Сила: взять карту из колоды за 1д.',
  factionInfo: 'Рыцари и ангелы полагающиеся на магию света и силу своих воинов',
  DummyCard: 'Получите 1 очко действия до конца вашего хода',
};
