export default {
  ImperialShooter: 'Имперский стрелок',
  MasterShooter: 'Мастер стрелок',
  YoungPriestess: 'Молодая жрица',
  WarriorOfLight: 'Воин света',
  ValorKnight: 'Благородный рыцарь',
  Bannerman: 'Знаменосец',
  Defender: 'Заступник',
  RighteousFighter: 'Праведная воительница',
  KnightGuardian: 'Рыцарь-защитник',
  ImperialGriffon: 'Имперский Гриффон',
  TemplarChampion: 'Тамплиер Чемпион',
  MasterOfLight: 'Магистр света',
  ThunderBlast: 'Удар грома',
  Swiftness: 'Стремительность',
  Resurrection: 'Воскрешение',
  Retribution: 'Воздаяние',
  AttackAura: 'Аура атаки',
  HealingLight: 'Исцеление светом',
  YouthFountain: 'Фонтан молодости',
  Enlightenment: 'Просветление',
  HeavenProtection: 'Божественная защита',
  Bless: 'Благословение',
  HolyLand: 'Святая земля',
  HeavenShock: 'Шок небес',
  SaintWord: 'Святое слово',
  RighteousHammer: 'Молот правосудия',
  RetaliationHammer: 'Молот возмездия',
  LightShield: 'Щит света',
  LastChance: 'Последний шанс',
  Conciliation: 'Умиротворение',
  TemplarChampCast: 'Заклинание',
  BannermanCast: 'Заклинание',
  ZigfridHero: 'Зигфрид - Герой',
  DummyCard: 'Тренировка',
};
